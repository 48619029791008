module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ProCliq","short_name":"ProCliq","start_url":"/","background_color":"#ffffff","theme_color":"#27a9e0","display":"minimal-ui","icon":"src/images/logo-square.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"79e5ff59f0c34b3735bc02b2507eee52"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-128972752-1","head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallbackWhitelist":[{}],"navigateFallbackBlacklist":[{}],"cacheId":"gatsby-plugin-offline","dontCacheBustUrlsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"networkFirst"},{"urlPattern":{},"handler":"networkFirst"}],"skipWaiting":true,"clientsClaim":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

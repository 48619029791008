import $ from "jquery";

const useIntercom =
  typeof window !== "undefined" && typeof window.Intercom === "function";

export const startIntercom = () => {
  // Don't track while developing.
  if (useIntercom)
    window.Intercom("boot", {
      app_id: "u5uq7vht",
    });
};

export const updateIntercom = () => {
  // Don't track while developing.
  if (useIntercom) window.Intercom("update");
};

export const shutdownIntercom = () => {
  if (useIntercom) window.Intercom("shutdown");
};

export const openSignupPage = () => {
  shutdownIntercom();
  $("body").css("overflow", "hidden");
  $("#signup").addClass("open");
};

export const closeSignupPage = () => {
  startIntercom();
  $("body").css("overflow", "auto");
  $("#signup").removeClass("open");
};

// @flow

"use strict";

const { startIntercom, updateIntercom } = require("./src/helpers");

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
  // Don't use while developing
  process.env.NODE_ENV === "production" && startIntercom();
};

exports.onRouteUpdate = ({ location }) => {
  // Don't use while developing
  process.env.NODE_ENV === "production" && updateIntercom();
};
